// extracted by mini-css-extract-plugin
export var article = "styles-module--article--da26e";
export var article__datetime = "styles-module--article__datetime--a2988";
export var article__intro = "styles-module--article__intro--f211c";
export var article__readMore = "styles-module--article__read-more--343ff";
export var article__tag = "styles-module--article__tag--d3ac9";
export var article__tags = "styles-module--article__tags--a0db9";
export var articles = "styles-module--articles--afa7f";
export var articlesSection = "styles-module--articles-section--92352";
export var head = "styles-module--head--9ba97";
export var heart = "styles-module--heart--d9f3b";
export var intro = "styles-module--intro--78675";